import React from 'react';
import styled from 'styled-components';

export const CEBAddress = () => (
  <StyledAddress>
    <dl>
      <dt>
        <img
          src="/images/ceb-logo-2024.svg"
          alt="Continuing Education of the Bar"
        />
      </dt>
      <dd>CEB Publishing Distribution Center (PDC)</dd>
      <dd>745 85th Avenue, Suite F</dd>
      <dd>Oakland, CA 94621</dd>
    </dl>
  </StyledAddress>
);

const StyledAddress = styled.address`
  font-style: normal;

  img {
    vertical-align: bottom;
  }

  dt {
    margin-bottom: 19px;
  }

  dd {
    margin: 0.5em 0;
  }

  span {
    white-space: nowrap;
  }
`;
