import React, { useContext } from 'react';
import { useHeapIdentify } from './useHeapIdentify';
import { useBugsnagUser } from './useBugsnagUser';
import { useWrappedIntercom } from 'components/app/useWrappedIntercom';
import { AppContext } from '.';
import { useAssetAccess } from 'components/asset-access';
import { IntercomProps } from 'react-use-intercom';

export default function Analytics(props: { children: React.ReactNode }) {
  const { user } = useContext(AppContext);

  const { hasOnlawProducts } = useAssetAccess();

  const assetProperties = {
    hasOnLaw: Boolean(hasOnlawProducts),
  };

  const intercom = useWrappedIntercom();

  useHeapIdentify(user, assetProperties);
  useBugsnagUser(user);

  intercom.update(assetProperties as Partial<IntercomProps>);

  return props.children;
}
