import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { BUGSNAG_API_KEY, BUGSNAG_RELEASE_STAGE } from 'app-constants';

export const bugsnagClient =
  process.env.NODE_ENV !== 'test' && BUGSNAG_API_KEY
    ? Bugsnag.start({
        apiKey: BUGSNAG_API_KEY,
        releaseStage: BUGSNAG_RELEASE_STAGE || 'localhost',
        notifyReleaseStages: ['production', 'staging'],
        plugins: [new BugsnagPluginReact()],
      })
    : undefined;
